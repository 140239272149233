<template>
    <v-container fluid class="mb-12 pb-12">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
        <v-col cols="12">
            <v-card outlined>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="deep-purple accent-4"
                ></v-progress-linear>
                <v-container fluid>
                    <v-row>
                        <v-col class="col-12" sm="6" md="2">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date"
                                    label="Period"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    dense
                                    solo
                                    class="border-12"
                                    hide-details=""
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="date"
                                type="month"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="mill"
                                :items="mills"
                                item-value="mill_id"
                                item-text="mill_name"
                                label="MILL ID"
                                @change="(event) => updateWarehouse(event)"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="division"
                                :items="divisions"
                                item-value="class"
                                item-text="descr"
                                label="Division"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="warehouse"
                                :items="warehouses"
                                item-value="wh_id"
                                item-text="descr"
                                label="WH ID"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="bin"
                                :items="bins"
                                item-value="bin_id"
                                item-text="bin_id"
                                label="BIN ID"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="batch"
                                :items="batchs"
                                item-value="batch_id"
                                item-text="batch_id"
                                label="Batch ID"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="machine"
                                :items="machines"
                                item-value="mach_type"
                                item-text="descr"
                                label="Machine"
                                @change="(event) => updateGroupJenis(event)"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="group"
                                :items="groups"
                                item-value="jenis"
                                item-text="jenis"
                                label="Group ID"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="ord_flg"
                                :items="ord_flgs"
                                item-value="ord_flg"
                                item-text="ord_flg"
                                label="Order Flag"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="thick"
                                :items="thicks"
                                item-value="tebal"
                                item-text="tebal"
                                label="Thickness"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="width"
                                :items="widths"
                                item-value="lebar"
                                item-text="lebar"
                                label="Width"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="color"
                                :items="colors"
                                item-value="warna"
                                item-text="namawarna"
                                label="Color ID"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="length"
                                :items="lengths"
                                item-value="panjang"
                                item-text="panjang"
                                label="Length"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="quality"
                                :items="qualities"
                                item-value="quality_id"
                                item-text="quality_id"
                                label="Quality"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="product_code"
                                :items="product_codes"
                                item-value="prod_code"
                                item-text="prod_code"
                                label="Product Code"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="add_id"
                                :items="add_ids"
                                item-value="add_id"
                                item-text="add_id"
                                label="ADD ID"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                clearable
                                v-model="tor_id"
                                :items="tor_ids"
                                item-value="tor_id"
                                item-text="tor_id"
                                label="Tora ID"
                                dense
                                solo
                                class="border-12"
                                hide-details=""
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3" align="center" justify="center">
                        <v-col class="col-6" sm="6" md="2">
                            <v-btn
                            block
                            @click="clear()"
                            class="border-12"
                            >Clear</v-btn>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-btn
                            block
                            color="success"
                            class="border-12"
                            @click="search()"
                            ><v-icon>mdi-magnify</v-icon> Search</v-btn>
                        </v-col>
                    </v-row>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                        
                </v-container>
            </v-card>
        </v-col>
        <v-col cols="12" style="padding-bottom: 100px">
            <v-card v-show="display == 'none' ? false: true">
                <v-container fluid v-show="displayOrdN == 'none' ? false: true">
                    <v-row>
                        <v-col cols="12">
                            <v-card-title>Result
                            </v-card-title>
                            <v-data-table
                                :headers="headers"
                                :items="wh_bals"
                                class="elevation-1"
                                :items-per-page="30"
                                :footer-props="{
                                    'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                }"
                                :loading="$store.state.overlay"
                                height="425"
                                fixed-header
                                :divider="true"
                                :light="true"
                                :search="searchItemTrans" 
                                :item-class="tr_datatable"
                            >
                                <template v-slot:top>
                                    <v-toolbar flat color="white" class="mt-2 mb-2">
                                        <div class="d-flex w-100">
                                            <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            <v-spacer></v-spacer>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>Detail</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container fluid v-show="displayOrdY == 'none' ? false: true">
                    <v-row>
                        <v-col cols="12">
                            <v-card-title>Result
                            </v-card-title>
                            <v-data-table
                                :headers="headerOrders"
                                :items="wh_bals"
                                class="elevation-1"
                                :items-per-page="30"
                                :footer-props="{
                                    'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                }"
                                :loading="$store.state.overlay"
                                height="425"
                                fixed-header
                                :divider="true"
                                :light="true"
                                :search="searchItemOrder" 
                                :item-class="tr_datatable"
                            >
                                <template v-slot:top>
                                    <v-toolbar flat color="white" class="mt-2 mb-2">
                                        <div class="d-flex w-100">
                                            <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemOrder" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            <v-spacer></v-spacer>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>Detail</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
            <v-card v-show="display == 'none' ? true : false" height="475">
                <v-card-text>
                    <v-img
                    class="text-center mt-12 mx-auto"
                    lazy-src="@/assets/empty_data.png"
                    max-width="300"
                    src="@/assets/empty_data.png"
                    ></v-img>
                    <h6 class="indigo-text text-center m-0">Please Fill Field then click search </h6>
                </v-card-text>
            </v-card>
            <v-dialog v-model="dialog" max-width="1000px">                
                <v-card>
                    <v-card-title class="text-center mb-0 pb-0">
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex">
                                    <div class="ml-5">
                                        Detail 
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        x-small
                                        color="#e83e8c;"
                                        @click="close"
                                    >
                                        <v-icon dark>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-divider></v-divider>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                            <v-container fluid>
                            <v-row>
                                <v-col cols="12">
                                    <v-data-table
                                        :headers="header_temp_details"
                                        :items="wh_bal_details"
                                        class="elevation-1"
                                        :items-per-page="30"
                                        :footer-props="{
                                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                        }"
                                        :loading="$store.state.overlay"
                                        height="425"
                                        fixed-header
                                        :divider="true"
                                        :light="true"
                                        :search="searchItemDetail" 
                                        :item-class="tr_datatable"
                                    >
                                        <template v-slot:top>
                                            <v-toolbar flat color="white" class="mt-2 mb-2">
                                                <div class="d-flex w-100">
                                                    <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemDetail" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                    <v-spacer></v-spacer>
                                                </div>
                                            </v-toolbar>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>

export default {
    data() {
        return {
            loading: false,
            loading2: false,
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'KKA Production',
                disabled: false,
                href: '/admin/kka/production',
                },
                {
                text: 'Information',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Stock Query',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            mills: [],
            mill: '',
            divisions: [],
            division: '',
            warehouses: [],
            warehouse: '',
            bin: '',
            bins: ['02'],
            batchs: [],
            batch: '',
            date: new Date().toISOString().substr(0, 7),
            dateHist: new Date().toISOString().substr(0, 7).replace(/-/g,""),
            menu: false,
            machines: [],
            machine: '',
            groups: [],
            group: '',
            ord_flgs: ['N', 'H', 'Y'],
            ord_flg: 'N',
            thicks: [],
            thick: '',
            widths: [],
            width: '',
            colors: [],
            color: '',
            lengths: [],
            length: '',
            qualities: [],
            quality: '',
            product_codes: [],
            product_code: '',
            add_ids: [],
            add_id: '',
            tor_ids: [],
            tor_id: '',
            display: 'none',
            displayOrdN: 'none',
            displayOrdY: 'none',
            wh_bals: [],
            wh_bal_details: [],
            totalItems: 10,
            totalItem_temp_details: 10,
            options: {},
            headers: [
                { text: 'Actions', align: 'start', value: 'actions', sortable: false },
                {
                    text: 'WH',
                    sortable: true,
                    value: 'wh_id',
                },
                { text: 'Bin', value: 'bin_id' },
                { text: 'Prod Code', value: 'prod_code' },
                { text: 'Description', value: 'NamaBarang' },
                { text: 'Color', value: 'NamaWarna' },
                { text: 'Add ID', value: 'add_id' },
                { text: 'TOR ID', value: 'tor_id' },
                { text: 'ACP ID', value: 'acp_id' },
                { text: 'Length', value: 'panjang' },
                { text: 'Qty OH', value: 'qty_oh' },
                { text: 'Qty Awal', value: 'qty_awal' },
                { text: 'Qty Prod', value: 'qty_prod' },
                { text: 'Qty Rcv', value: 'qty_rcv' },
                { text: 'Qty Iss', value: 'qty_iss' },
                { text: 'Qty Ship', value: 'qty_ship' },
                { text: 'Qty IA', value: 'qty_ia' },
                { text: 'Batch ID', value: 'batch_id' },
                { text: 'Quality', value: 'quality_id' },
                
            ],
            headerOrders: [
                { text: 'Actions', align: 'start',  value: 'actions', sortable: false },
                {
                    text: 'WH',
                    sortable: true,
                    value: 'wh_id',
                },
                { text: 'Bin', value: 'bin_id' },
                { text: 'Prod Code', value: 'prod_code' },
                { text: 'Description', value: 'NamaBarang' },
                { text: 'Color', value: 'NamaWarna' },
                { text: 'Add ID', value: 'add_id' },
                { text: 'TOR ID', value: 'tor_id' },
                { text: 'ACP ID', value: 'acp_id' },
                { text: 'Length', value: 'panjang' },
                { text: 'Qty Avail', value: 'qty_avail' },
                { text: 'Qty Rcv', value: 'qty_rcv' },
                { text: 'Qty Iss', value: 'qty_iss' },
                { text: 'Qty Ship', value: 'qty_ship' },
                { text: 'Batch ID', value: 'batch_id' },
                { text: 'Grade', value: 'grade' },
            ],
            header_temp_details: [
                {
                    text: 'Nama Customer',
                    align: 'start',
                    sortable: true,
                    value: 'NamaCustomer',
                },
                { text: 'TR Date', value: 'tr_date', sortable: true },
                { text: 'TR ID', value: 'tr_id', sortable: true },
                { text: 'TR Type', value: 'tr_type' },
                { text: 'WH ID', value: 'wh_id' },
                { text: 'Bin ID', value: 'bin_id' },
                { text: 'Prod Code', value: 'prod_code' },
                { text: 'Add ID', value: 'add_id' },
                { text: 'TOR ID', value: 'tor_id' },
                { text: 'ACP ID', value: 'acp_id' },
                { text: 'Length', value: 'panjang' },
                { text: 'Batch ID', value: 'batch_id' },
                { text: 'Qty Prod', value: 'qtyPROD' },        
                { text: 'Wgt Prod', value: 'wgtPROD' },
                { text: 'Qty RCV', value: 'qtyRCV' },        
                { text: 'Wgt RCV', value: 'wgtRCV' },
                { text: 'Qty Iss', value: 'qtyISS' },        
                { text: 'Wgt Iss', value: 'wgtISS' },
                { text: 'Qty Ship', value: 'qtySHIP' },        
                { text: 'Wgt Ship', value: 'wgtSHIP' },
                { text: 'Qty Adj', value: 'qtyADJ' },        
                { text: 'Wgt Adj', value: 'wgtADJ' },
                { text: 'Order Flag', value: 'ord_flag' },
                { text: 'Order ID', value: 'tr_id_ref' },
                { text: 'Order Num', value: 'tr_id_seq_ref' },
                { text: 'Remark', value: 'remark' },
                { text: 'User', value: 'user_id' }
            ],
            data_var: {
                entity_id : 'KKA',
                appl_id : 'MILLPRODUCTION'
            },
            data_temp_wh: {},
            dialog: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            option_temp_details: {},
            pagination: 1,
            paginationOrder: 1,
            pagination_item: 1,
            searchItemTrans: '',
            searchItemOrder: '',
            searchItemDetail: ''
        }
    },
    mounted(){
        this.getMill()
    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        getMill() {   
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'MILLID') {
                        this.mill = res.data.data[i]['var_value']
                    }
                    
                    if (res.data.data[i]['var_id'] === 'WHID') {
                        this.warehouse = res.data.data[i]['var_value']
                    }
                }

                this.getMills()

                if(this.mill){
                    this.updateWarehouse(this.mill)
                } 

            })
            
        },
        getMills(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill?mill_id=${this.mill ? this.mill : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.mills = res.data.data
            });
        },
        updateWarehouse(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/warehouse?mill_id=${value}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.warehouses = res.data
            });

            this.getDivision(value)
            this.getBatch(value)
            this.getMachine(value)
            this.getLength(value)
            this.getQuality(value)
            this.getProdCode(value)
            this.getAddId(value)
            this.getTorId(value)

        },
        getDivision(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/division?mill_id=${value}&status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.divisions = res.data
            });
        },
        getBatch(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/batch?mill_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.batchs = res.data
            });
        },
        getMachine(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/machine_brg_jadi?mill_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.machines = res.data
            });
        },
        getLength(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/lengt_wh_bal?mill_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.lengths = res.data
            });
        },  
        getQuality(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/quality?mill_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.qualities = res.data
            });
        },
        getProdCode(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/prod_code?mill_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.product_codes = res.data
            });
        },
        getAddId(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/add_id?mill_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.add_ids = res.data
            });
        },
        getTorId(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/tor_id?mill_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.tor_ids = res.data
            });
        },
        updateGroupJenis(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/jenis_brg_jadi?mach_type=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.groups = res.data
            });

            this.getTebal(value)
            this.getLebar(value)
            this.getColor(value)

        },
        getTebal(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/tebal_brg_jadi?mach_type=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.thicks = res.data
            });
        },
        getLebar(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/lebar_brg_jadi?mach_type=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.widths = res.data
            });
        },
        getColor(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/color_brg_jadi?mach_type=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.colors = res.data
            });
        },
        showItem(item){
            this.data_temp_wh = item
            this.wh_bal_details = []
            this.getDetail(item)
        },
        close() {
            this.dialog = false
        },
        async getDetail(item){
            this.$store.dispatch('setOverlay', true)
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/stock_query/show?mill_id=${item.mill_id}&wh_id=${item.wh_id}&bin_id=${item.bin_id}&period=${item.period}&panjang=${item.panjang}&prod_code=${item.prod_code}&batch_id=${item.batch_id}&add_id=${item.add_id}&acp_id=${item.acp_id}&tor_id=${item.tor_id}&ord_flag=${item.ord_flag}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.$store.dispatch('setOverlay', false)
                this.wh_bal_details = res.data
                this.dialog = true
            })
        },
        clear(){
            this.mill = ''
            this.division = ''
            this.warehouse = ''
            this.bin = ''
            this.batch = ''
            this.machine = ''
            this.group = ''
            this.ord_flg = ''
            this.thick = ''
            this.width = ''
            this.color = ''
            this.length = ''
            this.quality = ''
            this.product_code = ''
            this.add_id = ''
            this.tor_id = ''
            this.display = 'none'
            this.displayOrdY = 'none'
            this.displayOrdN = 'none'
            this.loading = false
            this.$store.state.loading = false
        },
        search(){
            var date = new Date(this.date).toISOString().substr(0, 7).replace(/-/g,"")

            if (this.mill === '' || this.mill === null || this.date === '' || this.date === null || this.ord_flg === '' || this.ord_flg === null) {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill MILL, period, Order Flag in the data first",
                    visible: true
                };
                return false;
            } else {
                this.loading = true
                this.$store.state.loading = true

                if (this.ord_flg === 'N' || this.ord_flg === 'H') {
                    this.pagination = 1


                    if (this.dateHist === date) {
                        axios.get(`${process.env.VUE_APP_URL}/api/kka/stock_query?mill_id=${this.mill}&period=${date}&mach_type=${this.machine ? this.machine : ''}&class=${this.division ? this.division : ''}&jenis=${this.group ? this.group : ''}&tebal=${this.thick ? this.thick : ''}&warna=${this.color ? this.color : ''}&lebar=${this.width ? this.width : ''}&quality_id=${this.quality ? this.quality : ''}&panjang=${this.length ? this.length : ''}&wh_id=${this.warehouse ? this.warehouse : ''}&bin_id=${this.bin ? this.bin : ''}&prod_code=${this.product_code ? this.product_code : ''}&batch_id=${this.batch ? this.batch : ''}&add_id=${this.add_id ? this.add_id : ''}&tor_id=${this.tor_id ? this.tor_id : ''}&ord_flag=${this.ord_flg ? this.ord_flg : ''}`, { 
                            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                        })
                        .then(res => {
                            this.display = 'block'
                            this.displayOrdN = 'block'
                            this.displayOrdY = 'none'
                            this.loading = false
                            this.$store.state.loading = false
                            this.wh_bals = res.data
                        }) 
                    } else{
                        axios.get(`${process.env.VUE_APP_URL}/api/kka/stock_query/history?mill_id=${this.mill}&period=${date}&mach_type=${this.machine ? this.machine : ''}&class=${this.division ? this.division : ''}&jenis=${this.group ? this.group : ''}&tebal=${this.thick ? this.thick : ''}&warna=${this.color ? this.color : ''}&lebar=${this.width ? this.width : ''}&quality_id=${this.quality ? this.quality : ''}&panjang=${this.length ? this.length : ''}&wh_id=${this.warehouse ? this.warehouse : ''}&bin_id=${this.bin ? this.bin : ''}&prod_code=${this.product_code ? this.product_code : ''}&batch_id=${this.batch ? this.batch : ''}&add_id=${this.add_id ? this.add_id : ''}&tor_id=${this.tor_id ? this.tor_id : ''}&ord_flag=${this.ord_flg ? this.ord_flg : ''}`, { 
                            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                        })
                        .then(res => {
                            this.display = 'block'
                            this.displayOrdN = 'block'
                            this.displayOrdY = 'none'
                            this.loading = false
                            this.$store.state.loading = false
                            this.wh_bals = res.data

                        }) 
                    }

                    
                } else if (this.ord_flg === 'Y') {

                    this.paginationOrder = 1
                    
                    axios.get(`${process.env.VUE_APP_URL}/api/kka/stock_query/getOrder?mill_id=${this.mill}&period=${date}&mach_type=${this.machine ? this.machine : ''}&class=${this.division ? this.division : ''}&jenis=${this.group ? this.group : ''}&tebal=${this.thick ? this.thick : ''}&warna=${this.color ? this.color : ''}&lebar=${this.width ? this.width : ''}&quality_id=${this.quality ? this.quality : ''}&panjang=${this.length ? this.length : ''}&wh_id=${this.warehouse ? this.warehouse : ''}&bin_id=${this.bin ? this.bin : ''}&prod_code=${this.product_code ? this.product_code : ''}&batch_id=${this.batch ? this.batch : ''}&add_id=${this.add_id ? this.add_id : ''}&tor_id=${this.tor_id ? this.tor_id : ''}&ord_flag=${this.ord_flg ? this.ord_flg : ''}`, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    })
                    .then(res => {
                        this.display = 'block'
                        this.displayOrdY = 'block'
                        this.displayOrdN = 'none'
                        this.loading = false
                        this.$store.state.loading = false
                        this.wh_bals = res.data
                    })
                }
                
            }
        }
    },
    watch: {
    }
}
</script>